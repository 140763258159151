var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"searchBar",staticClass:"chat-container",on:{"click":_vm.cancelOther}},[_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/images/bg_gradient.png"),"alt":""}}),_c('header',{staticClass:"header"},[_c('van-icon',{staticClass:"back-icon",attrs:{"name":"arrow-left","size":"22"},on:{"click":_vm.back}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nameChangeShow),expression:"nameChangeShow"}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newName),expression:"newName"}],staticClass:"input-name",attrs:{"type":"text"},domProps:{"value":(_vm.newName)},on:{"blur":_vm.saveName,"input":function($event){if($event.target.composing){ return; }_vm.newName=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.nameChangeShow),expression:"!nameChangeShow"}],staticClass:"header-text"},[_c('span',[_vm._v(_vm._s(_vm.title))]),_c('img',{attrs:{"src":require("@/assets/images/changeName.png")},on:{"click":_vm.changeName}})]),(_vm.deletes)?_c('span',{staticClass:"remove-text",on:{"click":_vm.removeChat}},[_vm._v(" 删除话题 ")]):_c('span')],1),_c('main',{ref:"scrollDiv",staticClass:"chat-content",style:({
      'max-height': _vm.expand
        ? ("calc(100vh - " + _vm.bottomHeight + "px - 1rem  )")
        : 'calc(100vh - 2.9rem)',
    }),on:{"click":function($event){_vm.removeShow = false}}},[_c('van-pull-refresh',{attrs:{"loosing-text":"加载中...","pulling-text":"下拉加载历史聊天...","disabled":_vm.disabled},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"","immediate-check":false},on:{"load":_vm.getTalkList}},[_c('section',{ref:"chatWindow",staticClass:"chat-list"},[_vm._l((_vm.messageList),function(item,index){return _c('ul',{key:index,class:{
              'customer-server-msg': !_vm.messageIsSelf(item),
              'customer-msg': _vm.messageIsSelf(item),
              'customer-sendTime': item.time && item.sender == 1,
            },attrs:{"id":("item" + index)}},[(item.time && item.sender == 1)?_c('li',{staticClass:"msg-info-time"},[_c('p',[_vm._v(_vm._s(_vm._f("time")(item.time)))])]):_vm._e(),_c('div',{staticClass:"meta"},[_c('div',{staticClass:"avater-area"},[(item.sender != 1)?_c('div',{staticClass:"avater",style:({
                    'border-color': item.titleColor,
                    color: item.titleColor,
                  })},[_vm._v(" "+_vm._s(item.title.slice(0, 4))+" ")]):_c('img',{attrs:{"src":require("@/assets/images/qq.png"),"alt":""}})]),_c('li',{staticClass:"msg-info"},[_c('div',{staticClass:"msg-info-con",class:{ 'customer-bgc': _vm.messageIsSelf(item) },on:{"touchstart":function($event){return _vm.touchstart($event, index)},"touchend":_vm.touchend}},[_c('MarkdownPreview',{staticClass:"parser",attrs:{"initialValue":item.msg,"markedOptions":{
                      width: 100,
                    }}})],1)])])])}),(_vm.isSend)?_c('div',{staticClass:"wait"},[_c('img',{attrs:{"src":require("@/assets/images/lod3.gif")}})]):_vm._e()],2)])],1)],1),_c('transition',{attrs:{"name":"van-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPopover),expression:"showPopover"}],staticClass:"fast-tools",class:[
        _vm.showTop
          ? _vm.domWidth - _vm.toolsWidth > 0
            ? 'top-tip'
            : 'top-tip-less'
          : _vm.domWidth - _vm.toolsWidth > 0
          ? 'down-tip'
          : 'down-tip-less' ],style:({
        top: ("" + (_vm.showTop
            ? ((_vm.domOffsetTop - 76) + "px")
            : _vm.clientHeight > _vm.domHeight
            ? ((_vm.domHeight + _vm.domOffsetTop + 10) + "px")
            : _vm.clientHeight - _vm.domOffsetBottom > 0
            ? ((_vm.domOffsetBottom + 10) + "px ")
            : ("calc(" + _vm.clientHeight + "px - 3rem)"))),
        left: ("" + (!_vm.isSelf ? ("calc(" + (_vm.domWidth / 2 - _vm.toolsWidth / 2) + "px + .3rem) ") : '')),
        right: ("" + (_vm.isSelf ? '.9rem' : '')),
      })},[_c('div',{staticClass:"fast-item",on:{"click":_vm.copy}},[_c('img',{attrs:{"src":require("@/assets/images/icon_copy.png"),"alt":""}}),_c('span',[_vm._v("复制")])]),_c('div',{staticClass:"fast-item",on:{"click":_vm.delRecord}},[_c('img',{attrs:{"src":require("@/assets/images/icon_del.png"),"alt":""}}),_c('span',[_vm._v("删除")])])])]),_c('div',{staticClass:"bottom",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"helper"},[_vm._l((_vm.heplerList),function(item,index){return _c('div',{staticClass:"helper-item",on:{"click":function($event){return _vm.toggleHelper(index)}}},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('span',{style:({ color: _vm.currnetHelperIndex == index ? '#294BF9' : '' })},[_vm._v(_vm._s(item.title))])])}),_c('van-checkbox',{staticClass:"concat-content",class:[_vm.isConcatContent && 'active-concat'],attrs:{"icon-size":"12","checked-color":"#3B5BFF"},model:{value:(_vm.isConcatContent),callback:function ($$v) {_vm.isConcatContent=$$v},expression:"isConcatContent"}},[_vm._v("联系上下文")]),_c('span',{staticClass:"modelName"},[_vm._v(_vm._s(_vm.model.modelName))])],2),_c('div',{staticClass:"send-area"},[_c('van-field',{staticClass:"text",attrs:{"placeholder":"和Ta说点什么~","rows":"1","type":"textarea","autosize":{ maxHeight: 150 }},on:{"focus":_vm.enBottom,"blur":_vm.enBlur},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('img',{attrs:{"src":_vm.text
            ? require('@/assets/images/icon_send_active.png')
            : require('@/assets/images/icon_send.png'),"alt":""},on:{"click":_vm.en}})],1),_c('transition',{attrs:{"name":"van-slide-up"}},[_c('van-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],staticClass:"role-list",attrs:{"finished":_vm.roleFinished,"offset":"50","immediate-check":false},on:{"load":_vm.getRoleList},model:{value:(_vm.roleLoading),callback:function ($$v) {_vm.roleLoading=$$v},expression:"roleLoading"}},_vm._l((_vm.roleList),function(item){return _c('span',{staticClass:"text-overflow",style:({ background: item.background }),on:{"click":function($event){return _vm.toggleRole(item)}}},[_vm._v(_vm._s(item.title))])}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }