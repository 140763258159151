<template>
  <div class="chat-container" ref="searchBar" @click="cancelOther">
    <img src="@/assets/images/bg_gradient.png" alt="" class="bg" />
    <header class="header">
      <van-icon name="arrow-left" size="22" class="back-icon" @click="back" />

      <div v-show="nameChangeShow">
        <input
          type="text"
          class="input-name"
          v-model="newName"
          @blur="saveName"
        />
      </div>
      <div class="header-text" v-show="!nameChangeShow">
        <span>{{ title }}</span>
        <img src="@/assets/images/changeName.png" @click="changeName" />
      </div>
      <span class="remove-text" v-if="deletes" @click="removeChat">
        删除话题
      </span>
      <span v-else></span>
    </header>
    <!-- `max-height : calc(100vh - 2.9rem)`,`min-height:calc(100vh - 2.9rem)` -->
    <main
      class="chat-content"
      ref="scrollDiv"
      @click="removeShow = false"
      :style="{
        'max-height': expand
          ? `calc(100vh - ${bottomHeight}px - 1rem  )`
          : 'calc(100vh - 2.9rem)',
      }"
    >
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        loosing-text="加载中..."
        pulling-text="下拉加载历史聊天..."
        :disabled="disabled"
      >
        <!-- v-model="loading" -->
        <van-list
          :finished="finished"
          finished-text=""
          @load="getTalkList"
          :immediate-check="false"
        >
          <section class="chat-list" ref="chatWindow">
            <ul
              v-for="(item, index) in messageList"
              :key="index"
              :class="{
                'customer-server-msg': !messageIsSelf(item),
                'customer-msg': messageIsSelf(item),
                'customer-sendTime': item.time && item.sender == 1,
              }"
              :id="`item${index}`"
            >
              <li v-if="item.time && item.sender == 1" class="msg-info-time">
                <p>{{ item.time | time }}</p>
              </li>
              <div class="meta">
                <div class="avater-area">
                  <div
                    class="avater"
                    v-if="item.sender != 1"
                    :style="{
                      'border-color': item.titleColor,
                      color: item.titleColor,
                    }"
                  >
                    {{ item.title.slice(0, 4) }}
                  </div>
                  <img src="@/assets/images/qq.png" alt="" v-else />
                </div>

                <!-- @click.stop="log($event, index)" -->
                <li class="msg-info">
                  <div
                    class="msg-info-con"
                    @touchstart="touchstart($event, index)"
                    @touchend="touchend"
                    :class="{ 'customer-bgc': messageIsSelf(item) }"
                  >
                    <MarkdownPreview
                      :initialValue="item.msg"
                      :markedOptions="{
                        width: 100,
                      }"
                      class="parser"
                    />
                  </div>
                  <!-- <div
                    class="concat-list"
                    v-if="item.sender != 1 && messageList.length - 1 == index"
                  >
                    <span v-for="item in 2">联想提示?</span>
                  </div> -->
                </li>
              </div>
            </ul>
            <div class="wait" v-if="isSend">
              <img src="@/assets/images/lod3.gif" />
            </div>
          </section>
        </van-list>
      </van-pull-refresh>
    </main>
    <!-- `calc(${clientHeight}px - 3rem)` -->
    <transition name="van-fade">
      <div
        class="fast-tools"
        :style="{
          top: `${
            showTop
              ? `${domOffsetTop - 76}px`
              : clientHeight > domHeight
              ? `${domHeight + domOffsetTop + 10}px`
              : clientHeight - domOffsetBottom > 0
              ? `${domOffsetBottom + 10}px `
              : `calc(${clientHeight}px - 3rem)`
          }`,
          left: `${
            !isSelf ? `calc(${domWidth / 2 - toolsWidth / 2}px + .3rem) ` : ''
          }`,
          right: `${isSelf ? '.9rem' : ''}`,
        }"
        :class="[
          showTop
            ? domWidth - toolsWidth > 0
              ? 'top-tip'
              : 'top-tip-less'
            : domWidth - toolsWidth > 0
            ? 'down-tip'
            : 'down-tip-less',
        ]"
        v-show="showPopover"
      >
        <div class="fast-item" @click="copy">
          <img src="@/assets/images/icon_copy.png" alt="" />
          <span>复制</span>
        </div>

        <div class="fast-item" @click="delRecord">
          <img src="@/assets/images/icon_del.png" alt="" />
          <span>删除</span>
        </div>
      </div>
    </transition>

    <div class="bottom" @click.stop>
      <div class="helper">
        <div
          class="helper-item"
          v-for="(item, index) in heplerList"
          @click="toggleHelper(index)"
        >
          <img :src="item.icon" alt="" />
          <span
            :style="{ color: currnetHelperIndex == index ? '#294BF9' : '' }"
            >{{ item.title }}</span
          >
        </div>
        <van-checkbox
          v-model="isConcatContent"
          class="concat-content"
          :class="[isConcatContent && 'active-concat']"
          icon-size="12"
          checked-color="#3B5BFF"
          >联系上下文</van-checkbox
        >
        <span class="modelName">{{ model.modelName }}</span>
      </div>
      <div class="send-area">
        <van-field
          v-model="text"
          class="text"
          placeholder="和Ta说点什么~"
          @focus="enBottom"
          @blur="enBlur"
          rows="1"
          type="textarea"
          :autosize="{ maxHeight: 150 }"
        />
        <img
          :src="
            text
              ? require('@/assets/images/icon_send_active.png')
              : require('@/assets/images/icon_send.png')
          "
          alt=""
          @click="en"
        />
      </div>
      <transition name="van-slide-up">
        <van-list
          v-show="expand"
          v-model="roleLoading"
          :finished="roleFinished"
          @load="getRoleList"
          class="role-list"
          offset="50"
          :immediate-check="false"
        >
          <!-- <div class="role-list" > -->
          <span
            v-for="item in roleList"
            class="text-overflow"
            :style="{ background: item.background }"
            @click="toggleRole(item)"
            >{{ item.title }}</span
          >

          <!-- </div> -->
        </van-list>
      </transition>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/utils/youngbook";
import { getToken } from "@/utils/auth";
import { MarkdownPreview } from "vue-meditor";
import { fetchEventSource } from "@microsoft/fetch-event-source";

//   window.addEventListener('resize', function () {
//        this.$toast('调用键盘')
//     var height = window.innerHeight;
//     var inputHeight = document.getElementById('input').offsetHeight;
//     var bottomHeight = document.getElementById('bottom').offsetHeight;
//     if (height < inputHeight + bottomHeight) {
//     document.getElementById("bottom").style.position = 'absolute';
//     } else {
//         document.getElementById( 'bottom').style.position = 'fixed';
//     }
//     })

export default {
  data() {
    return {
      title: "",
      text: "",

      loading: true,
      successText: "刷新成功",
      loosingText: "",

      //是否修改名字
      nameChangeShow: false,
      newName: "",

      //删除展示
      removeShow: false,

      messageList: [
        // {m:'1',sendTime:'2022-03-02',content:'1'},
      ],
      id: undefined,
      deletes: false,
      chatId: "",
      //判断是否返回
      isTrue: false,
      queryKeys: {
        userId: localStorage.getItem("id"),
        pageNum: 1,
        pageSize: 6,
      },
      refreshing: false,
      loading: false,
      finished: false,
      // 是否禁用下拉刷新
      disabled: false,
      // 消耗的token
      tokens: "",
      showPopover: false,
      currentIndex: -1,
      currentCopyText: "",
      timerId: undefined,
      // 是否发送消息
      isSend: false,
      // 视口高度
      clientHeight: 0,
      // 长按元素顶部离页面顶部距离
      domOffsetTop: 0,
      // 长按元素底部离页面顶部距离
      domOffsetBottom: 0,
      // 长按元素宽度
      domWidth: 0,
      // 是否是点击自己的信息
      isSelf: false,
      // 快捷弹窗显示位置  上下
      showTop: true,
      // 点击元素高度
      domHeight: 0,
      // 工具栏宽度
      toolsWidth: 0,
      touchTimer: null,
      rangeObj: null,
      // 触发加载来源  loading refresh
      action: "loading",
      bottomHeight: 0,
      expand: false,
      currnetHelperIndex: -1,
      heplerList: [
        {
          title: "",
          icon: require("@/assets/images/icon_helper.png"),
        },
        // {
        //   title: "脑洞大开",
        //   icon: require("@/assets/images/icon_hook.png"),
        // },
      ],
      roleList: [],
      roleQueryKeys: {
        userId: localStorage.getItem("id"),
        pageNum: 1,
        pageSize: 10,
      },
      roleLoading: false,
      roleFinished: false,
      roleInit: false,
      currentRole: {},
      gradients: [
        "linear-gradient(270deg, #EDF1F8 0%, #E6EFFF 100%)",
        "linear-gradient(270deg, #EDF1F8 0%, #E3E3EF 100%)",
        "linear-gradient(270deg, #EDF1F8 0%, #E7F7F5 100%)",
        "linear-gradient(270deg, #EFF3F6 0%, #FEF7E4 100%)",
        "linear-gradient(270deg, #EFF4F8 0%, #F0F9E6 100%)",
        "linear-gradient(270deg, #EEF1F6 0%, #F2E7E5 100%)",
        "linear-gradient(270deg, #EFF2F9 0%, #E1E5E4 100%)",
        "linear-gradient(270deg, #EEF1F6 0%, #E7EEE3 100%)",
        "linear-gradient(270deg, #EFF2F7 0%, #EFE4EC 100%)",
      ],
      isConcatContent: false,
      // 联系上下文条数
      concatCount: 5,
      // 上下文消息列表
      concatList: [],
      model: {
        modelName: "",
      },
    };
  },
  components: { MarkdownPreview },
  filters: {
    time(time) {
      if (!time) {
        return "";
      }
      return parseTime(time);
    },
  },
  mounted() {
    this.getCurrentModel();
    this.clientHeight = document.body.clientHeight;
    document.oncontextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("selectionchange", (e) => {
      this.currentCopyText = getSelection().toString();
    });
    document.querySelector(".chat-content").addEventListener("scroll", () => {
      this.cancelTools();
    });

    // 解决 防止微信下拉出现网址和加载数据冲突
    document.body.addEventListener(
      "touchmove",
      function (e) {
        if (!e._isScroller) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
    this.overscroll(document.querySelector(".chat-content"))

    if (!this.$route.query.is) {
      this.deletes = true;
    }
    if (this.$route.query.time) {
      this.title = this.$route.query.time;
      this.deletes = false;
      this.addChat();
      return;
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.init();
  },
  methods: {
    overscroll(el) {
      el.addEventListener("touchstart", function () {
        var top = el.scrollTop,
          totalScroll = el.scrollHeight,
          currentScroll = top + el.offsetHeight;
        if (top === 0) {
          el.scrollTop = 1;
        } else if (currentScroll === totalScroll) {
          el.scrollTop = top - 1;
        }
      });
      el.addEventListener("touchmove", function (evt) {
        if (el.offsetHeight < el.scrollHeight)
          //如果当前盒子的可视高度  <  实际高度,则开启触摸滑动事件
          evt._isScroller = true;
      });
    },
    cancelOther() {
      if (this.expand) {
        this.expand = false;
      }
      this.cancelTools();
    },
    toggleRole(item) {
      this.heplerList[0].title = item.title;
      this.currentRole = item;
      this.expand = false;
    },
    async getRoleList() {
      this.roleLoading = true;
      const res = await this.$api.role.getRoleListForChat(this.roleQueryKeys);
      res.data.rows.map(
        (item) =>
          (item.background =
            this.gradients[Math.floor(Math.random() * this.gradients.length)])
      );
      this.roleList = [...this.roleList, ...res.data.rows];
      this.roleLoading = false;
      if (!this.roleInit) {
        this.currentRole = this.roleList[0];
        this.heplerList[0].title = this.currentRole.title;
        this.roleInit = true;
      }
      if (this.roleQueryKeys.pageSize > res.data.rows.length) {
        this.roleFinished = true;
        return;
      }
      this.roleQueryKeys.pageNum++;
    },
    toggleHelper(index) {
      this.currnetHelperIndex = index;
      this.expand = index == 0;
      this.$nextTick(() => {
        this.bottomHeight =
          document.querySelector(".bottom")?.clientHeight ?? 0;
        this.toBottom();
      });
    },
    touchstart(e, index) {
      this.touchTimer = setTimeout(() => {
        this.log(e, index);
      }, 600);
    },
    touchend() {
      clearTimeout(this.touchTimer);
    },
    cancelTools() {
      if (this.showPopover) {
        this.showPopover = false;
      }

      getSelection().toString() && getSelection().empty();
    },
    async openai() {
      const ctrl = new AbortController();
      fetchEventSource("https://fivebaby.top/v1/chat/completions", {
        method: "post",
        headers: {
          Authorization:
            "Bearer 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",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "user",
              content: "你是谁",
            },
          ],
          temperature: 0.2,
          n: 1,
          stream: true,
          top_p: 1,
          max_tokens: 2048,
          presence_penalty: 0.0,
          frequency_penalty: 0.0,
        }),
        signal: ctrl.signal,
        onmessage: (ev) => {
          if (ev.data == "[DONE]") {
            console.log("取消");
            ctrl.abort();
            return;
          }
          const resloveMessage = JSON.parse(ev.data);
          // console.log(resloveMessage);
          this.isSend = false;
        },
      });
    },
    local(index) {
      // 原参数构造
      // console.log("参数",{
      //       userId: this.queryKeys.userId, // 用户编号
      //       topicId: this.id, // 话题编号
      //       roleId: this.currentRole.id,
      //       title: this.currentRole.title,
      //       titleColor: this.currentRole.titleColor,
      //       content: this.currentRole.content,
      //       question: this.text,
      //       model:"gpt-4"
      //     });

      if (this.isConcatContent) {
        if (this.messageList.length > this.concatCount * 2) {
          this.concatList = this.messageList
            .slice(this.messageList.length - 1 - this.concatCount * 2)
            .map((item) => {
              return {
                role: item.sender == 1 ? "user" : "assistant",
                content: item.msg,
              };
            });
        } else {
          this.concatList = this.messageList.map((item) => {
            return {
              role: item.sender == 1 ? "user" : "assistant",
              content: item.msg,
            };
          });
        }
        console.log(this.concatList);
      }

      const ctrl = new AbortController();
      fetchEventSource(
        `${process.env.VUE_APP_CHAT_BASE_API}${this.model.path}`,
        // // `${process.env.VUE_APP_BASE_API}/smart/api/ask/answerStream`,
        // `${process.env.VUE_APP_CHAT_BASE_API}/py/rpc/openAi/answer/front`, //直连3.0
        // //  `${process.env.VUE_APP_CHAT_BASE_API}/py/rpc/openAi/answer/front/gpt4`,//直连4.0
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          },
          // body: JSON.stringify({
          //   userId: this.queryKeys.userId, // 用户编号
          //   topicId: this.id, // 话题编号
          //   question,
          // }),
          body: JSON.stringify({
            userId: this.queryKeys.userId, // 用户编号
            topicId: this.id, // 话题编号
            roleId: this.currentRole.id,
            title: this.currentRole.title,
            titleColor: this.currentRole.titleColor,
            content: this.currentRole.content,
            question: this.isConcatContent ? this.concatList : this.text,
            model: "gpt-4o",
          }),
          signal: ctrl.signal,
          onmessage: (ev) => {
            // console.log(ev);
            if (ev.data == "[DONE]") {
              console.log("取消");
              ctrl.abort();
              return;
            }
            const message = JSON.parse(ev.data);
            if (!this.messageList[index]) {
              this.messageList.push({
                sender: "0",
                title: this.currentRole.title,
                titleColor: this.currentRole.titleColor,
                time: parseTime(Date.now()),
                msg: message.choices[0].delta.content ?? "",
              });
            }
            if (this.messageList[index]) {
              if (
                message.choices &&
                message.choices[0].delta &&
                message.choices[0].delta.content
              ) {
                this.messageList[index].msg += message.choices[0].delta.content;
              }
            }
            this.$nextTick(() => {
              this.toBottom();
            });
            this.isSend = false;
          },
          onerror: () => {
            // this.$toast("服务器异常,请稍后再试!");
            // ctrl.abort();
          },
          // onopen: (res) => {
          //   time++;
          //   time == 2 && ctrl.abort();
          // },
        }
      );
    },
    toBottom() {
      setTimeout(() => {
        this.scrollToBottom();
        scroll({ top: 50000, behavior: "smooth" });
      }, 300);
    },
    log(e, index) {
      if (e.target.className.includes("msg-info-con")) {
        this.domOffsetTop = Math.floor(e.target.getBoundingClientRect().top);
        this.domOffsetBottom = Math.ceil(
          e.target.getBoundingClientRect().bottom
        );
        this.domHeight = Math.ceil(e.target.getBoundingClientRect().height);
        this.domWidth = Math.ceil(e.target.getBoundingClientRect().width);

        this.isSelf = e.target.className.includes("customer-bgc");
      } else if (e.target.offsetParent.className.includes("msg-info-con")) {
        this.domOffsetTop = Math.floor(
          e.target.offsetParent.getBoundingClientRect().top
        );
        this.domOffsetBottom = Math.ceil(
          e.target.offsetParent.getBoundingClientRect().bottom
        );
        this.domHeight = Math.ceil(
          e.target.offsetParent.getBoundingClientRect().height
        );
        this.domWidth = Math.ceil(
          e.target.offsetParent.getBoundingClientRect().width
        );
        this.isSelf = e.target.offsetParent.className.includes("customer-bgc");
      } else {
        this.domOffsetTop = Math.floor(
          e.target.offsetParent.offsetParent.getBoundingClientRect().top
        );
        this.domOffsetBottom = Math.ceil(
          e.target.offsetParent.offsetParent.getBoundingClientRect().bottom
        );
        this.domHeight = Math.ceil(
          e.target.offsetParent.offsetParent.getBoundingClientRect().height
        );
        this.domWidth = Math.ceil(
          e.target.offsetParent.offsetParent.getBoundingClientRect().width
        );
        this.isSelf =
          e.target.offsetParent.offsetParent.className.includes("customer-bgc");
      }

      this.showPopover = true;
      this.currentIndex = index;
      this.$nextTick(() => {
        const domTools = document
          .querySelector(".fast-tools")
          .getBoundingClientRect();
        this.toolsWidth = Math.ceil(domTools.width);

        this.showTop = this.domOffsetTop - Math.ceil(domTools.height + 66) > 0;
      });

      if (
        e.target.offsetParent &&
        e.target.offsetParent.className == "code-block"
      )
        return;

      this.currentCopyText =
        e.target.offsetParent && e.target.offsetParent.innerText;
      this.rangeObj = e.target.offsetParent.firstChild;
    },
    combo(target) {
      let range = document.createRange();
      range.selectNodeContents(target);
      getSelection().addRange(range);
    },
    copy() {
      this.showPopover = false;
      const input = document.createElement("input");
      input.value = this.currentCopyText;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      input.remove();
      this.$toast("复制成功!");
    },
    delRecord() {
      this.showPopover = false;
      this.$dialog
        .confirm({
          message: "确认删除?",
          confirmButtonText: "删除",
        })
        .then(() => {
          this.$api.chat
            .delRecord({
              messageId: this.messageList[this.currentIndex].messageId,
            })
            .then((res) => {
              this.messageList.splice(this.currentIndex, 1);
            });
        })
        .catch(() => {});
    },
    async getTopicInfo() {
      const res = await this.$api.chat.getTopicInfo({ id: this.id });
      this.title = res.data.titleName;
    },
    back() {
      this.$router.back();
    },
    init() {
      this.getTopicInfo();
      this.getTalkList();
    },
    //新增话题
    addChat() {
      this.$api.chat
        .addAsk({
          userId: localStorage.id,
          title: this.title,
        })
        .then((res) => {
          if (res.code == 200) {
            this.topicId = res.data.id;
            this.id = res.data.id;
            this.init();
          }
        });
    },
    // 刷新
    onRefresh() {
      // this.queryKeys.pageSize = 2;
      this.finished = false;
      this.loading = true;
      this.action = "refresh";
      this.getTalkList();
    },
    //打开话题
    getTalkList() {
      this.loading = true;
      this.$api.chat
        .getAskDetail({ ...this.queryKeys, topicId: this.id })
        .then((res) => {
          if (res.code === 200) {
            this.loading = false;
            this.finished = true;
            this.refreshing = false;

            this.messageList = [...res.data.rows, ...this.messageList];
            const beforeHeight = document.querySelector(".chat-list").clientHeight;
            this.$nextTick(() => {
              this.$refs.scrollDiv.scrollTo({
                top: document.querySelector(".chat-list").clientHeight-beforeHeight
              });
            });
            if (!this.roleInit) {
              if (res.data.rows.length) {
                const lastRecord = res.data.rows[res.data.rows.length - 1];
                if (!lastRecord.delFlag && lastRecord.title) {
                  this.heplerList[0].title = lastRecord.title;
                  this.currentRole = lastRecord;
                  this.roleInit = true;
                }
              }
              this.getRoleList();
            }
            // if (!this.roleInit && res.data.rows.length) {
            //   const lastRecord = res.data.rows[res.data.rows.length - 1];
            //   if (!lastRecord.delFlag&&!lastRecord.title) {
            //     this.heplerList[0].title = lastRecord.title;
            //     this.currentRole = lastRecord;
            //     console.log("设置角色 getTalkList")
            //     console.log(this.currentRole)
            //     this.roleInit = true;
            //   }
            //   this.getRoleList();
            // }

            if (this.$route.query.is) {
              this.deletes = false;
              if (this.$route.query.text) {
                this.text = this.$route.query.text;
                this.en();
              }
            }

            if (res.data.rows.length < this.queryKeys.pageSize) {
              this.disabled = true;
              return;
            }

            this.queryKeys.pageNum++;
          }
        });
    },
    //修改名字
    changeName() {
      (this.nameChangeShow = true), (this.newName = "");
      this.removeNO();
      this.$nextTick(() => {
        document.querySelectorAll(".input-name")[0].focus();
      });
    },
    //展示删除
    remove() {
      this.removeShow = !this.removeShow;
    },
    removeChat() {
      this.$dialog
        .confirm({
          title: "",
          message: "是否删除对话?",
          confirmButtonText: "删除",
          className: "van-reverse",
        })
        .then(() => {
          // on confirm
          this.$api.chat.removeChat(this.id).then((res) => {
            if (res.code === 200) {
              this.$toast("删除成功");
              this.back();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    removeNO() {
      if (this.removeShow) {
        this.removeShow = false;
      }
    },
    messageIsSelf(message) {
      //TODO 报null错误？
      // console.log(message);
      if (message && message.sender) {
        return message.sender != 0;
      }
    },

    en() {
      if (!this.text) return;
      if (this.isSend) {
        this.$toast("AI正在努力为您查询,请稍等一下哦~~~");
        return;
      }
      this.isSend = true;

      if (
        !this.messageList.length ||
        Math.abs(
          Date.parse(this.messageList[this.messageList.length - 1].time) -
            Date.now()
        ) >
          1000 * 60 * 5
      ) {
        this.messageList.push({
          sender: "1",
          title: this.currentRole.title,
          titleColor: this.currentRole.titleColor,
          time: parseTime(Date.now()),
          msg: this.text,
        });
      } else {
        this.messageList.push({
          sender: "1",
          title: this.currentRole.title,
          titleColor: this.currentRole.titleColor,
          time: "",
          msg: this.text,
        });
      }
      this.toBottom();
      this.$nextTick(() => {
        this.local(this.messageList.length);
        this.text = "";
      });
    },
    scrollToBottom() {
      const scrollElem = this.$refs.scrollDiv;

      scrollElem.scrollTo({
        top: scrollElem.scrollHeight,
        behavior: "smooth",
      });
    },
    demoStr(arr) {
      if (this.chatIndex < arr.length) {
        this.cal = arr.substring(0, this.chatIndex++) + "_";
        this.messageList[this.messageList.length - 1].content = this.cal;
        this.timer = setTimeout(() => {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
          this.demoStr(arr);
        }, 100);
      } else {
        this.messageList[this.messageList.length - 1].content = arr;

        this.isTrue = false;
      }
    },
    enIs(text) {
      this.messageList.push({
        sendByName: "1",
        sendTime: parseTime(Date.now()),
        content: text,
      });
      this.messageList.push({
        sendByName: "Open",
        sendTime: parseTime(Date.now()),
        is: true,
      });

      for (let i = this.messageList.length - 1; i > 0; i--) {
        if (
          this.timeToTimestamp(this.messageList[i].sendTime) -
            this.timeToTimestamp(this.messageList[i - 1].sendTime) <
          600000
        ) {
          //    console.log(i);
          this.messageList[i].sendTime = "";
        }
      }
      this.topicId = this.$route.query.id;

      let obj = {
        userId: localStorage.id,
        topicId: this.$route.query.id || this.topicId,
        question: text,
      };

      this.isTrue = true;
      console.log("聊天页面请求");
      this.$api.chat
        .getAsk(obj)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.messageList.pop(0, -1);
            this.messageList.push({
              sendByName: "Open",
              sendTime: res.data.time,
              is: false,
              content: "",
            });

            let arr = res.data.msg;
            this.chatIndex = 0;
            this.cal = "";
            this.demoStr(arr);

            for (let i = this.messageList.length - 1; i > 0; i--) {
              if (
                this.timeToTimestamp(this.messageList[i].sendTime) -
                  this.timeToTimestamp(this.messageList[i - 1].sendTime) <
                600000
              ) {
                this.messageList[i].sendTime = "";
              }
            }
          }
        })
        .catch((res) => {
          console.log(res, "no");
        });

      this.$nextTick(() => {
        this.$router.push({ query: {} });
      });

      // let newQuery = JSON.parse(JSON.stringify(this.$route.query)) // 先拷贝一个一模一样的对象
      // delete newQuery.page //再删除page
      // this.$router.replace({ query: newQuery }) //再把新的替换了
    },
    //时间转换时间戳
    timeToTimestamp(time) {
      let timestamp = Date.parse(new Date(time).toString());
      //timestamp = timestamp / 1000; //时间戳为13位需除1000，时间戳为13位的话不需除1000
      //   console.log(time + "的时间戳为：" + timestamp);
      return timestamp;
      //2021-11-18 22:14:24的时间戳为：1637244864707
    },
    enBlur() {
      document.oncontextmenu = (e) => {
        e.preventDefault();
      };
      this.toBottom();
    },
    enBottom() {
      this.expand = false;
      document.oncontextmenu = (e) => {};
      this.removeNO();
      this.toBottom();
    },
    deviceEnvir() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      let isWeiChat = u.match(/MicroMessenger/i) == "micromessenger"; // 判断是否是微信环境
      if (isAndroid === true) {
        // 安卓
        return "ANDROID";
      } else if (isIOS === true) {
        // IOS
        return "IOS";
      } else if (isWeiChat === true) {
        //
        return "WEICHAT";
      } else {
        return "PC";
      }
    },
    //保存名字
    saveName(e) {
      // if(e.keyCode == 13){
      if (!this.newName || this.newName.length >= 12) {
        this.$toast("请输入名字并在十二个字符以内!");
        this.newName = this.title;
        this.nameChangeShow = false;
        return;
      }
      this.nameChangeShow = false;

      this.$api.chat
        .changeChatName({
          id: this.id,
          title: this.newName,
        })
        .then((res) => {
          if (res.code === 200) {
            this.title = this.newName;
          }
        });

      // }
    },
    async getCurrentModel() {
      const res = await this.$api.chat.getCurrentModel({
        customerId: localStorage.getItem("id"),
      });
      this.model = res.data;
    },
  },
};
</script>
<style lang='less' scoped>
.bg {
  position: absolute;
  width: 100%;
  height: 3rem;
}
.selected {
  background: #d0d9f6;
  position: relative;

  &::before {
    content: "";
    height: 0.4rem;
    width: 1px;
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    content: "";
    height: 0.4rem;
    width: 1px;
    background-color: red;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
::v-deep.parser {
  .markdown-preview {
    max-width: 5rem;
    background: transparent;
    padding: 0 !important;
    // h2,h3{
    //   padding-bottom: 0;
    // }
    ul {
      margin-top: 0;
    }
    .copy-code {
      opacity: 1 !important;
      top: 15px;
    }
    pre {
      display: inline-block;
      white-space: pre-wrap;
      word-break: keep-all;
    }
  }
}
::v-deep.customer-msg {
  .markdown-preview {
    p {
      color: white;
    }
  }
}
.top-tip {
  &::after {
    position: absolute;
    bottom: -0.16rem;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: 0;
    height: 0;
    border-top: 0.1rem solid white;
    border-left: 0.1rem solid transparent;
    border-right: 0.1rem solid transparent;
    border-bottom: 0.1rem solid transparent;
  }
}
.top-tip-less {
  &::after {
    position: absolute;
    bottom: -0.16rem;
    right: 0.4rem;
    content: "";
    width: 0;
    height: 0;
    border-top: 0.1rem solid white;
    border-left: 0.1rem solid transparent;
    border-right: 0.1rem solid transparent;
    border-bottom: 0.1rem solid transparent;
  }
}
.down-tip {
  &::after {
    position: absolute;
    top: -0.16rem;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: 0;
    height: 0;
    border-top: 0.1rem solid transparent;
    border-left: 0.1rem solid transparent;
    border-right: 0.1rem solid transparent;
    border-bottom: 0.1rem solid white;
  }
}
.down-tip-less {
  &::after {
    position: absolute;
    top: -0.16rem;
    right: 0.4rem;
    content: "";
    width: 0;
    height: 0;
    border-top: 0.1rem solid transparent;
    border-left: 0.1rem solid transparent;
    border-right: 0.1rem solid transparent;
    border-bottom: 0.1rem solid white;
  }
}
.fast-tools {
  position: fixed;
  z-index: 2;
  padding: 0.3rem 0.4rem;
  box-sizing: border-box;
  border-radius: 0.1rem;
  box-shadow: 0 0 0.2rem 0 rgba(107, 121, 148, 0.1);
  background-color: white;
  display: flex;
  .fast-item {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    img {
      width: 0.26rem;
      height: 0.26rem;
      margin-bottom: 0.1rem;
    }
    span {
      font-size: 0.24rem;
      font-family: PingFang;
      font-weight: 500;
      color: #333333;
    }
    &:first-child {
      margin-right: 0.5rem;
      img {
        width: 0.22rem;
        height: 0.26rem;
      }
    }
  }
}

.van-pull-refresh {
  min-height: calc(100% - 0.4rem);
  min-height: 100%;
  user-select: initial;
  margin-bottom: 0.4rem;
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  position: relative;
  overflow: auto;

  background-color: #f5f7fb;
}
.input-name {
  background-color: transparent;
  text-align: center;
}

.chat-content {
  // background-color: #f5f7fb;
  padding: 0.16rem 0.4rem 0 /* 8/50 */;

  overflow: auto;

  .chat-list {
    user-select: none;
    width: 100%;
    box-sizing: border-box;

    ul {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      margin: 0.4rem /* 16/50 */ 0 0;

      .msg-info-time {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        background: rgba(217, 217, 217, 0.5);
        margin: 0.16rem /* 8/50 */ 0 0;
        margin-bottom: 0.4rem;
        padding: 0.12rem;
        border-radius: 0.04rem;
        font-size: 0.2rem /* 11/50 */;
        color: black;
      }

      li {
        font-size: 0.24rem /* 12/50 */;
        box-sizing: border-box;

        &.header-img {
          width: 0.72rem;
          height: 0.72rem;
          border-radius: 50% /* 3/50 */;
          overflow: hidden;
          background-color: #fff;
          text-align: center;

          & > span {
            font-size: 0.56rem /* 28/50 */;
          }

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        &.msg-info {
          user-select: none;
          position: relative;
          .concat-list {
            // position: absolute;
            // bottom: -0.5rem;
            // left: 0;
            width: 5.36rem;
            margin-top: 0.2rem;
            display: flex;
            overflow: auto;
            span {
              white-space: nowrap;
              display: inline-block;
              padding: 0.06rem 0.1rem;
              font-size: 0.22rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #7e7e7e;
              background-color: #c9dbff;
              margin-right: 0.2rem;
              border-radius: 0.06rem;
            }
          }
          .msg-info-con {
            max-width: 5.36rem;
            user-select: initial;
            background: #ffffff;
            border-radius: 0.04rem 0.2rem 0.2rem 0.2rem;

            padding: 0.16rem 0.3rem 0.14rem 0.3rem /* 4.8/50 */;
            position: relative;
            display: inline-block;
            font-size: 0.3rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 1.5 /* 19/50 */;
            letter-spacing: 0.02rem;
            word-spacing: 0.01rem;

            .copy-text {
              color: black;
              position: absolute;
              background: white;
              padding: 0.02rem 0.04rem;
              border-radius: 0.04rem;
              font-size: 0.26rem;
              top: 0;
              right: -0.8rem;
            }

            // .msg-tip {
            //     display: inline-block;
            //     width: 0;
            //     height: 0;
            //     border: 4px solid transparent;
            //     position: absolute;
            // }

            &.customer-bgc {
              // line-height: .36rem /* 19/50 */;
              padding: 0.16rem 0.3rem 0.14rem 0.3rem;
              font-size: 0.3rem;
              line-height: 1.5 /* 19/50 */;
              letter-spacing: 0.02rem;
              word-spacing: 0.01rem;
              font-weight: 500;

              font-family: PingFang SC;
              color: #ffffff;
              background-color: #3b5bff;

              .copy-text {
                left: -0.8rem;
                right: inherit;
              }
            }
          }
        }

        &.server-list {
          em {
            display: block;
            font-weight: bold;
          }

          span {
            text-indent: 0.32rem /* 16/50 */;
            text-decoration: underline;
            display: block;
            color: rgb(101, 173, 201);
          }
        }
      }

      &.customer-server-msg {
        .msg-info {
          margin: 0 0.42rem 0 0.2rem;

          .msg-info-con {
            word-wrap: break-word;
            word-break: break-all;
            box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.04);
            .msg-tip {
              border-right: 9px solid #fff;
              top: 5px;
              left: -12px;
            }
          }
        }
      }

      &.customer-msg {
        justify-content: flex-end;
        .avater-area {
          order: 1;
        }
        .msg-info {
          margin: 0 0.2rem /* 12/50 */ 0 0.72rem /* 38/50 */;
          text-align: right;

          .msg-info-con {
            border-radius: 0.2rem 0.04rem 0.2rem 0.2rem;
            text-align: left;
            word-wrap: break-word;
            word-break: break-all;

            .msg-tip {
              border-left: 9px solid #ffbebf;
              top: 5px;
              right: -12px;
            }
          }
        }
      }
      .meta,
      .avater-area {
        display: flex;
      }
      .avater-area {
        .avater {
          width: 0.64rem;
          height: 0.64rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.04rem 0.04rem;
          box-sizing: border-box;
          background: #ffffff;
          border-width: 1.5px;
          line-height: 0.2rem;
          border-style: solid;
          font-size: 0.18rem;
          font-weight: 400;
          text-align: center;
          border-radius: 50%;
        }
        img {
          width: 0.68rem;
          height: 0.68rem;
        }
      }
    }
    .wait {
      width: 0.6rem;
      text-align: center;
      padding: 0.2rem;
      margin-left: 0.2rem;
      background-color: #e8eaf1;
      line-height: 0;
      display: inline-block;
      border-radius: 0.2rem;
      img {
        width: 0.5rem;
      }
    }
  }
}

.header {
  position: sticky;

  top: 0;
  left: 0;
  z-index: 15;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.3rem;
  width: 100%;
  height: 1rem;
  font-size: 0.36rem;
  box-sizing: border-box;

  .header-text {
    user-select: none;
    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    max-width: 50%;
    box-sizing: border-box;
    position: relative;
    display: flex;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      position: absolute;
      top: 40%;

      right: -0.3rem;
      width: 0.2rem;
      height: 0.2rem;
    }
  }
  .remove-text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #333333;
    border-radius: 0.1rem;
    font-size: 0.28rem;
    text-align: center;
  }
}

.bottom {
  user-select: initial;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  // height: 1.86rem;
  background-color: #ffffff;
  padding: 0.24rem 0 0;
  box-sizing: border-box;
  box-shadow: 0 0 0.2rem 0 rgba(107, 121, 148, 0.1);
  border-radius: 0.4rem 0.4rem 0 0;

  .helper {
    display: flex;
    margin-bottom: 0.24rem;
    padding-left: 0.64rem;
    position: relative;
    ::v-deep .concat-content {
      // position: absolute;
      // right: .64rem;
      // top: 0;
      font-size: 0.24rem;
      .van-checkbox__label {
        margin-left: 0.08rem;
        color: #7e7e7e;
      }
    }
    ::v-deep .active-concat {
      .van-checkbox__label {
        color: #333333;
      }
    }
    .helper-item {
      display: flex;
      align-items: center;
      margin-right: 0.32rem;
      img {
        width: 0.3rem;
        height: 0.3rem;
      }
      span {
        margin-left: 0.06rem;
        font-size: 0.24rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .modelName {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      color: #828282;
      font-size: 0.2rem;
    }
  }
  .send-area {
    padding: 0 0.52rem 0.2rem 0.42rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 0.6rem;
      height: 0.6rem;
    }
  }
  .role-list {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    background-color: #fafafa;
    padding: 0.6rem 0.58rem 0 0.6rem;
    max-height: 3rem;
    span {
      width: 1.84rem;
      display: inline-block;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      margin-right: 0.4rem;
      margin-bottom: 0.4rem;
      text-align: center;
      padding: 0.14rem 0.2rem;
      box-sizing: border-box;
      font-size: 0.24rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: black;

      box-shadow: 0 0 0.04rem 0 rgba(0, 0, 0, 0.06);
      border-radius: 0.08rem;

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }

  .text {
    // margin: 0.14rem 0;
    padding: 0.22rem 0.3rem;
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    width: 5.72rem;
    // height: 0.7rem;
    background: #eef0f3;
    border-radius: 0.12rem;
    box-sizing: border-box;
  }
  .bottom-send {
    user-select: none;
    margin: 0 0 0 0.2rem;
    width: 1.4rem;
    height: 0.86rem;
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    background: #3b5bff;
    border-radius: 0.12rem;
  }
}

//聊天列表
.chat-lists {
  // display: flex;
  // flex: 1;
  margin-top: 0.6rem;
  // overflow-y:scroll;
  // overflow-x:hidden;
  // // height:  80vh;
  // padding-bottom:5rem;
}

.customer-sendTime {
  padding-top: 1.3rem;
  // height:  1.8rem;
  // display: flex;
  // align-items: flex-end !important;
}
</style>